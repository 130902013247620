import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { endpoints } from "../../global/endpoints";
import { config } from "../../global/config";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Modal from "react-responsive-modal";
import Loader from "components/Loader";
import toast from "react-hot-toast";
import XHRUpload from "@uppy/xhr-upload";

export default function PublicUpload() {
  const [searchParams, setSearchParams] = useSearchParams();
  const assetID = searchParams.get("asset_id");

  const [openUppy, setOpenUppy] = useState(false);
  const [expireLink, setExpireLink] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assetData, setAssetData] = useState({});
  const [load, setLoad] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const darkModeListener = window.matchMedia("(prefers-color-scheme: dark)");
    const changeMode = (e) => {
      setIsDarkMode(e.matches);
    };
    darkModeListener.addEventListener("change", changeMode);

    return () => {
      darkModeListener.removeEventListener("change", changeMode);
    };
  }, []);

  useEffect(() => {
    if (assetID) {
      async function getAssetData() {
        try {
          const data = await axios.get(
            config.COPY_EMBED_HOST +
              endpoints.getEmbedAssets.replace(":asset_id", assetID)
          );
          if (data.data.id) {
            setAssetData(data.data);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          if (error.response?.data?.error?.code === "asset_id_not_valid") {
            setError(true);
          } else {
            toast.error(
              error.response?.data?.error?.code || "Failed to fetch asset data"
            );
          }
        }
      }

      getAssetData();
    }
  }, [assetID]);

  useEffect(() => {
    if (assetData?.status === "upload-pending") {
      setOpenUppy(true);
      setExpireLink(false);
      setSuccess(false);
      setError(false);
    } else if (assetData?.status === "errored") {
      setOpenUppy(false);
      setExpireLink(true);
      setSuccess(false);
      setError(false);
    } else if (error || !assetID) {
      setOpenUppy(false);
      setExpireLink(false);
      setSuccess(false);
      setError(true);
    } else {
      setOpenUppy(false);
      setExpireLink(false);
      setSuccess(true);
      setError(false);
    }
  }, [assetData, assetID]);

  const uppy_config = new Uppy({
    id: "uppy_config",
    debug: true,
    autoProceed: true,
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ["video/*", "application/octet-stream", "audio/*"],
    },
    allowMultipleUploadBatches: false,
    hideUploadButton: true,
  })
    .use(XHRUpload, {
      endpoint: assetData.upload_url,
      method: "put",
      formData: false,
    })
    .on("complete", (result) => {
      if (result.successful.length > 0) {
        toast.success("Upload Successful.");
        window.gumletSegment.track("File uploaded via Upload Link", {
          asset_id: assetID,
        });
        setLoad(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoad(false);
        toast.error("Upload failed. Please try again.");
      }
    });

  if (loading) return <Loader />;

  return (
    <>
      <Modal
        open={openUppy}
        onClose={setOpenUppy}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <img
                className="navbar-brand-logo-mini"
                src="https://assets.gumlet.io/assets/round-logo.png?w=100&format=auto"
                style={{
                  maxWidth: "30px",
                }}
              />
              <h4 className="card-header-title">
                Gumlet user wants you to upload a file here
              </h4>
              <div></div>
            </div>
          </div>
          <div className="card-body p-2">
            {load ? (
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{
                  height: "300px",
                }}
              >
                <span
                  className="spinner-border spinner-border-sm mb-4"
                  style={{
                    width: "2rem",
                    height: "2rem",
                  }}
                />
                <span>Please wait....</span>
              </div>
            ) : (
              <Dashboard
                uppy={uppy_config}
                showProgressDetails={true}
                theme="auto"
                hideUploadButton={true}
                inline={true}
                proudlyDisplayPoweredByUppy={false}
                height={300}
              />
            )}
          </div>
        </div>
      </Modal>

      <Modal
        open={expireLink}
        onClose={setExpireLink}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="navbar-brand-logo-mini"
                src={
                  isDarkMode
                    ? "https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto"
                    : "https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto"
                }
                style={{
                  maxWidth: "100px",
                }}
              />
            </div>
          </div>
          <div className="card-body p-2">
            <div className="rounded w-100 d-flex justify-content-center align-items-center flex-column mt-6">
              <div className="d-flex justify-content-center align-items-center h-100">
                <i
                  className="bi bi-clock text-danger"
                  style={{
                    fontSize: "4rem",
                  }}
                />
              </div>
              <div className="d-flex flex-nowrap my-4">
                <h4 className="fs-4">
                  The link is expired. Ask for a new upload link.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={success}
        onClose={setSuccess}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="navbar-brand-logo-mini"
                src={
                  isDarkMode
                    ? "https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto"
                    : "https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto"
                }
                style={{
                  maxWidth: "100px",
                }}
              />
            </div>
          </div>
          <div className="card-body p-2">
            <div className="rounded w-100 d-flex justify-content-center align-items-center flex-column mt-6">
              <div className="d-flex justify-content-center align-items-center h-100">
                <i
                  className="bi bi-check-circle-fill text-success"
                  style={{
                    fontSize: "4rem",
                  }}
                />
              </div>
              <div className="d-flex flex-nowrap my-4">
                <h4 className="fs-4">Video uploaded successfully.</h4>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={error}
        onClose={setError}
        showCloseIcon={false}
        closeOnOverlayClick={false}
      >
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="navbar-brand-logo-mini"
                src={
                  isDarkMode
                    ? "https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto"
                    : "https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto"
                }
                style={{
                  maxWidth: "100px",
                }}
              />
            </div>
          </div>
          <div className="card-body p-2">
            <div className="rounded w-100 d-flex justify-content-center align-items-center flex-column mt-6">
              <div className="d-flex justify-content-center align-items-center h-100">
                <i
                  className="bi bi-exclamation-circle text-danger"
                  style={{
                    fontSize: "4rem",
                  }}
                />
              </div>
              <div className="d-flex flex-nowrap my-4">
                <h4 className="fs-4">The link is not valid.</h4>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
