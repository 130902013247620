import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { endpoints } from "global/endpoints";
import DataProvider from "context/DataContext";
import useOnClickOutside from "utils/outsideClick";
import useWindowSize from "utils/windowSize";
import { useLogout } from "hooks/auth/useLogout";
import { SidebarData } from "./data";
import SubMenu from "./SubMenu";
import ExtraHelpLinks from "./Extras/extraHelp";
import UpgradeNudge from "./UpgradeNudge";
import toast from "react-hot-toast";
import http from "utils/httpNew";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const [orgData, setOrgData] = useState({});
  const handleLogout = useLogout();
  const location = useLocation();
  const size = useWindowSize();
  let sidebarRef = useRef(null);
  const { theme, setOnSidebarClick, userData } = useContext(DataProvider);

  useOnClickOutside(sidebarRef, () => setOnSidebarClick(false));

  const itemProps = size.width < 1200 ? { ref: sidebarRef } : {};

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location) {
      setOnSidebarClick(false);
    }
  }, [location]);

  useEffect(() => {
    async function getOrgData() {
      try {
        const data = await http({}).getResponse(endpoints.org);
        setOrgData(data?.data);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        toast.error(error?.error?.message);
      }
    }
    getOrgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (loading) {
  //   return (
  //     <div className="px-3">
  //       <span className="placeholder col-12"></span>
  //       <span className="placeholder col-12"></span>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <aside
        {...itemProps}
        className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white navbar-white navbar-vertical-aside-initialized"
      >
        <div className="navbar-vertical-container">
          <div className="navbar-vertical-footer-offset">
            <a className="navbar-brand" href="/">
              {theme === "dark" ? (
                <img
                  className="navbar-brand-logo"
                  src="https://assets.gumlet.io/assets/gumlet-logo-white-font.png?w=240&format=auto"
                  alt="Logo"
                />
              ) : (
                <img
                  className="navbar-brand-logo"
                  src="https://assets.gumlet.io/assets/gumlet-logo-black-font.png?w=240&format=auto"
                  alt="Logo"
                />
              )}
              <img
                className="navbar-brand-logo-mini"
                src="https://assets.gumlet.io/assets/round-logo.png?w=100&format=auto"
                alt="Logo"
              />
            </a>
            <div
              className="navbar-vertical-content"
              style={{
                paddingBottom: "120px",
              }}
            >
              <div
                id="navbarVerticalMenu"
                className="nav nav-pills nav-vertical card-navbar-nav"
              >
                <Fragment>
                  {SidebarData.map((item, index) => {
                    return (
                      <SubMenu
                        item={item}
                        key={index}
                        theme={theme}
                        handleLogout={handleLogout}
                        userData={userData}
                      />
                    );
                  })}
                </Fragment>
              </div>
            </div>

            {/* navbar footer */}

            {(userData && userData?.roles?.includes("video-readonly")) ||
            (orgData &&
              (orgData?.plan_data?.video?.plan === "enterprise" ||
                orgData.stripe_account_loc === "ind")) ? (
              <></>
            ) : (
              <UpgradeNudge
                orgData={orgData}
                theme={theme}
                userData={userData}
              />
            )}
            <div
              className="navbar-vertical-footer justify-content-start"
              style={{
                background: theme === "dark" ? "#191C24" : "#ffffff",
              }}
            >
              <ExtraHelpLinks />
            </div>
          </div>
        </div>
      </aside>
      <div className="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-mobile-overlay"></div>
    </React.Fragment>
  );
}
