import React, { Suspense, lazy, Fragment, useEffect, useContext } from "react";
import { routePaths } from "global/routePaths";
import DataProvider from "context/DataContext";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import usePrefersColorScheme from "use-prefers-color-scheme";
import { NavLink, useLocation } from "react-router-dom";
import {
  GuardConfigProvider,
  GuardedRoute,
  GuardProvider,
  GuardedRoutes,
} from "react-router-guarded-routes";
import featureAccessMap from "global/rbac.json";
import useWindowSize from "utils/windowSize";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Home = lazy(() => import("./Home/Home"));
const Page404 = lazy(() => import("Pages/Page404/Page404"));

//Insights
const Properties = lazy(() => import("Pages/Insights/Properties/Properties"));
const TotalViews = lazy(() => import("Pages/Insights/TotalViews/TotalViews"));
const ViewsDetail = lazy(() => import("Pages/Insights/TotalViews/ViewsDetail"));
const AddProperties = lazy(() =>
  import("Pages/Insights/Properties/AddProperties/AddProperties")
);
const EditProperties = lazy(() =>
  import("Pages/Insights/Properties/EditProperties/EditProperties")
);
const GettingStarted = lazy(() =>
  import("Pages/Insights/GettingStarted/GettingStarted")
);
const Reports = lazy(() => import("Pages/Insights/Reports/index"));
const Dashboards = lazy(() => import("Pages/Insights/Dashboards/index"));

//Images
const AllImageSources = lazy(() => import("Pages/Image/Sources/AllSources"));
const AddImageSource = lazy(() => import("Pages/Image/Sources/AddSource"));
const SourceGettingStarted = lazy(() =>
  import("Pages/Image/Sources/GettingStarted")
);
const EditImageSource = lazy(() => import("Pages/Image/Sources/EditSource"));
// const Setup = lazy(() =>
//   import("Pages/Image/Sources-old/Shared/Instructions/Setup")
// );

const PurgeCache = lazy(() => import("Pages/Image/Cache"));
const Analytics = lazy(() => import("Pages/Image/Analytics"));
const AllReports = lazy(() => import("Pages/Image/Reports/AllReports"));
const AddReport = lazy(() => import("Pages/Image/Reports/AddReport"));
const EditReport = lazy(() => import("Pages/Image/Reports/EditReport"));
const AllAlerts = lazy(() => import("Pages/Image/Alerts/AllAlerts"));
const AddAlert = lazy(() => import("Pages/Image/Alerts/AddAlert"));
const EditAlert = lazy(() => import("Pages/Image/Alerts/EditAlert"));

//Organization
const Coupon = lazy(() => import("Pages/Organization/Coupons"));
const Webhook = lazy(() => import("Pages/Organization/Webhooks"));
const AddWebhook = lazy(() => import("Pages/Organization/Webhooks/Add"));
const EditWebhook = lazy(() => import("Pages/Organization/Webhooks/Edit"));
const Account = lazy(() => import("Pages/Organization/Accounts"));
const AddAccount = lazy(() => import("Pages/Organization/Accounts/Add"));
const EditAccount = lazy(() => import("Pages/Organization/Accounts/Edit"));
const DRM = lazy(() => import("Pages/Organization/DRM"));
const AccessDenied = lazy(() => import("Pages/Page403/index"));
const Billing = lazy(() => import("Pages/Organization/Billing"));
const ChangeBilling = lazy(() =>
  import("Pages/Organization/Billing/ChangeBilling")
);
//User
const ApiKeys = lazy(() => import("Pages/User/ApiKeys/index"));
const Session = lazy(() => import("Pages/User/Sessions/index"));
const Profile = lazy(() => import("Pages/User/Profile/index"));

// Video
const AllVideoSources = lazy(() => import("Pages/Video/Sources/AllSources"));
const AddVideoSource = lazy(() => import("Pages/Video/Sources/AddSource"));
const EditVideoSource = lazy(() => import("Pages/Video/Sources/EditSource"));
const PlayerSettings = lazy(() => import("Pages/Video/Sources/PlayerSettings"));
const VideoProtections = lazy(() =>
  import("Pages/Video/Sources/VideoProtections")
);

const AllVideoAlerts = lazy(() => import("Pages/Video/Alerts/AllAlerts"));
const AddVideoAlert = lazy(() => import("Pages/Video/Alerts/AddAlert"));
const EditVideoAlert = lazy(() => import("Pages/Video/Alerts/EditAlert"));
const DeleteVideoAlert = lazy(() => import("Pages/Video/Alerts/DeleteAlert"));

const AllVideoReports = lazy(() => import("Pages/Video/Reports/AllReports"));
const AddVideoReport = lazy(() => import("Pages/Video/Reports/AddReport"));
const EditVideoReport = lazy(() => import("Pages/Video/Reports/EditReport"));
const DeleteVideoReport = lazy(() =>
  import("Pages/Video/Reports/DeleteReport")
);

const AllVideoProfiles = lazy(() => import("Pages/Video/Profiles/AllProfiles"));
const AddProfile = lazy(() => import("Pages/Video/Profiles/AddProfile"));
const EditProfile = lazy(() => import("Pages/Video/Profiles/EditProfile"));
const DeleteVideoProfile = lazy(() =>
  import("Pages/Video/Profiles/DeleteProfile")
);

// video live stream
const AllStreamSources = lazy(() => import("Pages/Stream/Sources/AllSources"));
const AddLiveVideoStream = lazy(() => import("Pages/Stream/Sources/AddSource"));
const EditLiveStreamSources = lazy(() =>
  import("Pages/Stream/Sources/EditSource")
);
const LiveVideoAssetDetails = lazy(() => import("Pages/Stream/Assets/Details"));
const StreamManager = lazy(() => import("Pages/Stream/Assets/Manage"));
const LiveStreamAnalytics = lazy(() => import("Pages/Stream/Analytics"));

const VideoAnalytics = lazy(() => import("Pages/Video/Analytics"));
const TopAssets = lazy(() => import("Pages/Video/Analytics/TopAssets"));

//Video CMS
const VideoManage = lazy(() => import("Pages/Video/CMS/Manage"));
// const NewVideoCMS = lazy(() => import("Pages/Video/CMS/Manager"));
//get asset detials
const AssetDetails = lazy(() => import("Pages/Video/CMS/Details"));

const VideoInsights = lazy(() => import("Pages/Video/CMS/Analytics"));

//video impoters
const Importers = lazy(() => import("Pages/Video/Importers"));
const AddImporter = lazy(() => import("Pages/Video/Importers/Add"));
const HelpCenter = lazy(() => import("Pages/HelpCenter"));
const Review = lazy(() => import("Pages/Review"));

function GuardRouterComponent({ userData }) {
  const hasAccessRoutes = (to, from, next) => {
    //middle ware logic to authenticate request routes
    //split the routes ..

    var route = to.location.pathname;
    var maxLevel = 2;

    //this function validate whether org has particular feature access or not based on user's current roles.
    let levels = route.split("/");
    if (levels.length < 1) {
      return next(routePaths.accessDenied, { replace: true });
    }
    let validateRoute = roleHasAccess(
      userData.roles,
      levels.slice(1, maxLevel + 1)
    );
    if (validateRoute) return next();
    else return next(routePaths.accessDenied, { replace: true });
  };

  function roleHasAccess(roles, accessLevels) {
    if (typeof accessLevels === "undefined") {
      return false;
    }
    accessLevels = [].concat(accessLevels);
    // Goes through all access levels, and if any one of the access levels
    // doesn't exist in the roles, return false
    return !accessLevels.some((level) => {
      var accesses = featureAccessMap[level] || [];
      var intersection = roles.filter((value) => accesses.includes(value));
      if (intersection.length === 0) return true;
      return false;
    });
  }
  return (
    <GuardConfigProvider>
      <GuardProvider guards={[hasAccessRoutes]}>
        <GuardedRoutes>
          <GuardedRoute
            path={routePaths.accessDenied}
            element={<AccessDenied />}
          />
          <GuardedRoute path={routePaths.home} element={<Home />} />
          <GuardedRoute
            path={routePaths.insights.boards.base.route}
            element={<Dashboards />}
          />
          <GuardedRoute
            path={routePaths.insights.boards.report}
            element={<Reports />}
          />
          <GuardedRoute
            path={routePaths.insights.properties.base.route}
            element={<Properties />}
          />
          <GuardedRoute
            path={routePaths.insights.properties.add}
            element={<AddProperties />}
          />
          <GuardedRoute
            path={routePaths.insights.properties.edit}
            element={<EditProperties />}
          />
          <GuardedRoute
            path={routePaths.insights.views.base.route}
            element={<TotalViews />}
          />
          <GuardedRoute
            path="/insights/views/:id/:sessionid/:playerid/:propertyId"
            element={<ViewsDetail />}
          />
          <GuardedRoute
            path={routePaths.started}
            element={<GettingStarted />}
          />

          {/* Image routes  */}
          <GuardedRoute
            path={routePaths.image.sources.allsources}
            element={<AllImageSources />}
          />
          <GuardedRoute
            path={routePaths.image.gettingStarted}
            element={<SourceGettingStarted />}
          />
          <GuardedRoute
            path={routePaths.image.sources.editsource}
            element={<EditImageSource />}
          />
          {/* <GuardedRoute path={routePaths.image.setup} element={<Setup />} /> */}

          <GuardedRoute
            path={routePaths.image.sources.addsource}
            element={<AddImageSource />}
          />
          <GuardedRoute
            path={routePaths.image.cache}
            element={<PurgeCache />}
          />
          <GuardedRoute
            path={routePaths.image.analytics}
            element={<Analytics />}
          />
          <GuardedRoute
            path={routePaths.image.reports.allreports}
            element={<AllReports />}
          />
          <GuardedRoute
            path={routePaths.image.reports.addreport}
            element={<AddReport />}
          />
          <GuardedRoute
            path={routePaths.image.reports.editreport}
            element={<EditReport />}
          />
          <GuardedRoute
            path={routePaths.image.alerts.allalerts}
            element={<AllAlerts />}
          />
          <GuardedRoute
            path={routePaths.image.alerts.addalert}
            element={<AddAlert />}
          />
          <GuardedRoute
            path={routePaths.image.alerts.editalert}
            element={<EditAlert />}
          />

          {/* Video Collection Routes */}
          <GuardedRoute
            path={routePaths.video.sources.allsources}
            element={<AllVideoSources />}
          />
          <GuardedRoute
            path={routePaths.video.sources.addsource}
            element={<AddVideoSource />}
          />
          <GuardedRoute
            path={routePaths.video.sources.editsource}
            element={<EditVideoSource />}
          />
          <GuardedRoute
            path={routePaths.video.sources.embededsettings}
            element={<PlayerSettings />}
          />
          <GuardedRoute
            path={routePaths.video.sources.videoprotection}
            element={<VideoProtections />}
          />

          <GuardedRoute
            path={routePaths.video.analytics}
            element={<VideoAnalytics />}
          />
          <GuardedRoute
            path={routePaths.video.topAssets}
            element={<TopAssets />}
          />

          {/* Importers */}

          <GuardedRoute
            path={routePaths.video.importers.base}
            element={<Importers />}
          />

          <GuardedRoute
            path={routePaths.video.importers.add}
            element={<AddImporter />}
          />

          {/* Video CMS Routes */}
          <GuardedRoute
            path={routePaths.video.cms.manage}
            element={<VideoManage />}
          />

          {/* Get Asset Details  */}
          <GuardedRoute
            path={routePaths.video.cms.assetDetails}
            element={<AssetDetails />}
          />

          <GuardedRoute
            path={routePaths.video.cms.analytics}
            element={<VideoInsights />}
          />

          {/* Video Alerts Routes */}
          <GuardedRoute
            path={routePaths.video.alerts.allalerts}
            element={<AllVideoAlerts />}
          />
          <GuardedRoute
            path={routePaths.video.alerts.editalert}
            element={<EditVideoAlert />}
          />
          <GuardedRoute
            path={routePaths.video.alerts.addalert}
            element={<AddVideoAlert />}
          />
          <GuardedRoute
            path={routePaths.video.alerts.deletealert}
            element={<DeleteVideoAlert />}
          />

          {/* Video Reports Routes */}
          <GuardedRoute
            path={routePaths.video.reports.allreports}
            element={<AllVideoReports />}
          />
          <GuardedRoute
            path={routePaths.video.reports.addreport}
            element={<AddVideoReport />}
          />
          <GuardedRoute
            path={routePaths.video.reports.editreport}
            element={<EditVideoReport />}
          />
          <GuardedRoute
            path={routePaths.video.reports.deletereport}
            element={<DeleteVideoReport />}
          />

          {/* Video Profiles Routes */}
          <GuardedRoute
            path={routePaths.video.profiles.allprofiles}
            element={<AllVideoProfiles />}
          />
          <GuardedRoute
            path={routePaths.video.profiles.addProfile}
            element={<AddProfile />}
          />
          <GuardedRoute
            path={routePaths.video.profiles.editprofile}
            element={<EditProfile />}
          />
          <GuardedRoute
            path={routePaths.video.profiles.deleteprofile}
            element={<DeleteVideoProfile />}
          />

          {/* // live stream routes  */}

          <GuardedRoute
            path={routePaths.stream.sources.allsources}
            element={<AllStreamSources />}
          />

          <GuardedRoute
            path={routePaths.stream.sources.addsource}
            element={<AddLiveVideoStream />}
          />

          <GuardedRoute
            path={routePaths.stream.sources.editsource}
            element={<EditLiveStreamSources />}
          />

          <GuardedRoute
            path={routePaths.stream.assets.info}
            element={<LiveVideoAssetDetails />}
          />

          <GuardedRoute
            path={routePaths.stream.assets.base}
            element={<StreamManager />}
          />

          <GuardedRoute
            path={routePaths.stream.analytics}
            element={<LiveStreamAnalytics />}
          />

          {/* Org routes  */}
          <GuardedRoute
            path={routePaths.organization.billing.base}
            element={<Billing />}
          />
          <GuardedRoute
            path={routePaths.organization.billing.changebilling}
            element={<ChangeBilling />}
          />
          <GuardedRoute
            path={routePaths.organization.coupons}
            element={<Coupon />}
          />
          <GuardedRoute
            path={routePaths.organization.webhooks.base}
            element={<Webhook />}
          />
          <GuardedRoute
            path={routePaths.organization.webhooks.add}
            element={<AddWebhook />}
          />
          <GuardedRoute
            path={routePaths.organization.webhooks.edit}
            element={<EditWebhook />}
          />
          <GuardedRoute
            path={routePaths.organization.accounts.base}
            element={<Account />}
          />
          <GuardedRoute
            path={routePaths.organization.accounts.add}
            element={<AddAccount />}
          />
          <GuardedRoute
            path={routePaths.organization.accounts.edit}
            element={<EditAccount />}
          />
          <GuardedRoute path={routePaths.organization.drm} element={<DRM />} />
          <GuardedRoute path={routePaths.review} element={<Review />} />

          {/* User routes  */}
          <GuardedRoute path={routePaths.user.apikey} element={<ApiKeys />} />
          <GuardedRoute path={routePaths.user.sessions} element={<Session />} />
          <GuardedRoute path={routePaths.user.profile} element={<Profile />} />
          <GuardedRoute path={routePaths.help} element={<HelpCenter />} />
          <GuardedRoute path="*" element={<Page404 />} />
        </GuardedRoutes>
      </GuardProvider>
    </GuardConfigProvider>
  );
}

export default function AppRouter() {
  const { setTheme, userData, onSidebarClick } = useContext(DataProvider);
  const prefersColorScheme = usePrefersColorScheme();
  const isDarkMode = prefersColorScheme;
  const size = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  let isVerify;
  if (userData && userData.email_verified === true) {
    isVerify = "true";
  } else if (userData && userData.email_verified === false) {
    isVerify = "false";
  }

  var switchedUser = Cookies.get("switched_user");
  var originalUser = Cookies.get("original_user");

  useEffect(() => {
    setTheme(isDarkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode]);

  useEffect(() => {
    //segment page view tracking....
    if (typeof switchedUser === "undefined") {
      if (typeof gumletSegment !== "undefined") {
        if (location.pathname === "/video/manage" && location.search == "") {
          <></>;
        } else {
          window.gumletSegment.page("react-webapp"); // tracks pageview
        }
      }
    }
  }, [location, switchedUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // redirect to billing page if user did not paid
  useEffect(() => {
    if (userData && userData.unpaid) {
      navigate("organization/billing");
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (size.width < 1200) {
      document.body.classList.add("navbar-vertical-aside-closed-mode");
    } else {
      document.body.classList.remove("navbar-vertical-aside-closed-mode");
    }

    if (onSidebarClick && size.width < 1200) {
      document.body.classList.remove("navbar-vertical-aside-closed-mode");
    }
  }, [onSidebarClick, size]);

  return (
    <Fragment>
      <Header />
      <Sidebar />
      <main id="content" role="main" className="main">
        {switchedUser && location.pathname !== "/user/profile" ? (
          <div className="mb-8">
            <div className="navbar-sticky-lg-top" style={{ zIndex: 999 }}>
              <div className="alert alert-warning card-alert text-center py-2">
                <div className="row justify-content-end align-items-center">
                  <div className="col-sm-auto fs-6">
                    You are currently logged in as: <b>{switchedUser}</b>
                  </div>
                  <div className="col-auto">
                    <NavLink
                      className={`btn btn-xs ${
                        isDarkMode === "dark"
                          ? "btn-white text-white"
                          : "btn-dark"
                      }`}
                      to={routePaths.user.profile}
                    >
                      Switch Back to Admin
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {userData && isVerify === "false" ? (
          <div className="mb-0">
            <div
              className="alert card-alert alert-soft-danger card-center text-center"
              role="alert"
            >
              <strong>Verify your email!&nbsp;</strong>Please check your mailbox
              and verify your email to use Gumlet seamlessly
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="content container-fluid pt-1 pt-sm-4">
          <Suspense fallback={<></>}>
            {userData.id ? <GuardRouterComponent userData={userData} /> : <></>}
          </Suspense>
        </div>
      </main>
    </Fragment>
  );
}
