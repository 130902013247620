const text = {
  newPassword:
    "New Password must be at least 8 characters long and include at least one lowercase character, one uppercase character, and one number or symbol.",
  currentPassword: "Current Password cannot be blank.",
  new_alert: "Alerts can notify you if a metric does not look right.",
  new_report:
    "Scheduling reports can help you stay on top of video delivery metrics.",
  new_profile:
    "Video profiles are templates of video processing options. Once you create a profile, you can re-use it to process multiple videos.",
  upgrade_plan_for_feature:
    "Your plan doesn't have this feature, please upgrade your current plan to access this feature.",
  no_collections_description:
    "Video collections help you manage your videos at single place and helps you specify origin for your videos.",
  no_live_videos_description:
    "You are just one step away from creating your first source for live stream, click below button for create a new source",
  delete_alert_card_title: "Action",
  delete_alert_card_description:
    "You can delete this alert by clicking button below.",
  delete_alert_confirmation_text:
    "This video alert will be deleted. Are you sure you want to continue?",
  delete_alert_button_text: "Delete Alert",

  delete_report_card_title: "Action",
  delete_report_card_description:
    "You can delete this report by clicking button below.",
  delete_report_confirmation_text:
    "This video report will be deleted. Are you sure you want to continue?",
  delete_report_button_text: "Delete Report",

  delete_video_collection_card_title: "Action",
  delete_video_collection_card_description:
    "You can delete this collection by clicking button below.",
  delete_video_collection_confirmation_text:
    "This video collection along with all videos inside it will get deleted. Are you sure you want to continue?",
  delete_video_collection_button_text: "Delete Collection",
  delete_stream_collection_button_text: "Delete Collection",

  delete_video_profile_card_title: "Action",
  delete_video_profile_card_description:
    "You can delete the profile by clicking button below.",
  delete_video_profile_confirmation_text:
    "This video profile will be deleted. Are you sure you want to continue?",
  delete_video_profile_button_text: "Delete Profile",

  no_sources_description:
    "You are just one step away from creating your first source, click below button for create a new source",
  new_report_image:
    "Scheduling reports can help you stay on top of image delivery metrics.",
  delete_image_source_card_description:
    "You can delete this source by clicking button below.",
  delete_alert_confirmation_text_image:
    "This image alert will be deleted. Are you sure you want to continue?",
  delete_report_confirmation_text_image:
    "This image report will be deleted. Are you sure you want to continue?",
  delete_image_source_confirmation_text:
    "This image source will be deleted. Are you sure you want to continue?",
  delete_image_source_button_text: "Delete Source",

  delete_asset_card_title: "Action",
  delete_asset_card_description:
    "You can delete this asset by clicking button below.",
  delete_asset_confirmation_text:
    "This asset will be deleted. Are you sure you want to continue?",
  delete_asset_button_text: "Delete Asset",
  delete_stream_button_text: "Delete Stream",
  delete_live_collection_description:
    "You can delete this collection by clicking button below.",
  delete_live_collection_confirmation:
    "This collection will be deleted. Are you sure you want to continue?",
  delete_live_stream_description:
    "You can delete this live stream by clicking button below.",
  delete_live_stream_confirmation:
    "This live stream will be deleted. Are you sure you want to continue?",
};

export default text;
