/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from "react";
import { videoPricing, imagePricing } from "utils/constants";
import { PopupModal } from "react-calendly";
import { Modal } from "react-responsive-modal";
import DowngradeModal from "./DowngradeModal";

export default function PricingCard({
  price,
  columnClass,
  onPlanSelection,
  orgData,
  toggle,
  planType,
  userData,
  onCloseModal,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [OpenDowngradeModal, setOpenDowngradeModal] = useState(false);

  const ConfirmDowngradePlan = () => {
    onPlanSelection(price, "", planType, "downgrade", true);
    setOpenDowngradeModal(false);
  };

  function getDynamicButton() {
    if (
      orgData &&
      (orgData?.plan_data?.image?.plan === price.id ||
        orgData?.plan_data?.video?.plan === price.id)
    ) {
      return (
        <button className="btn btn-block btn-outline-success btn-sm" disabled>
          Current Plan
        </button>
      );
    } else {
      const currentUserImagePlanIndex = imagePricing.plans.findIndex(
        (plan) => orgData?.plan_data?.image?.plan === plan.id
      );

      const currentUserVideoPlanIndex = videoPricing.plans.findIndex(
        (plan) => orgData?.plan_data?.video?.plan === plan.id
      );

      const selectedImagePlanIndex = imagePricing.plans.findIndex(
        (plan) => plan.id === price.id
      );

      const selectedVideoPlanIndex = videoPricing.plans.findIndex(
        (plan) => plan.id === price.id
      );

      const isUpgrade =
        (currentUserImagePlanIndex < selectedImagePlanIndex &&
          planType === "image") ||
        (currentUserVideoPlanIndex < selectedVideoPlanIndex &&
          planType === "video");

      return orgData?.plan_data?.image?.display_name?.includes("Legacy") ||
        orgData?.plan_data?.video?.display_name?.includes("Legacy") ? (
        <button
          className="btn btn-block btn-primary btn-sm"
          onClick={() => onPlanSelection(price, "", planType)}
        >
          {"Switch to " + price.plan}
        </button>
      ) : (
        <button
          className={`btn btn-block ${
            !isUpgrade ? "btn-outline-primary" : "btn-primary"
          } btn-sm`}
          onClick={() =>
            isUpgrade
              ? onPlanSelection(price, "", planType)
              : setOpenDowngradeModal(true)
          }
        >
          {!isUpgrade ? "Downgrade Plan" : price.btnTxt}
        </button>
      );
    }
  }

  return (
    <Fragment>
      <div className={`${columnClass} col-sm-6  mb-3`}>
        <div className="card card-lg form-check form-check-select-stretched h-100 zi-1 card-hover-shadow">
          <div className="card-header d-block text-center py-3 px-0">
            <span className="card-subtitle uppercase pb-1">
              {price.plan_name_alias ? price.plan_name_alias : price.plan}
            </span>
            <div className="mb-2">
              {price.plan !== "Custom" ? (
                <h2 className="card-title display-5">
                  <span className="me-1">
                    {toggle ? price.yearlyPrice : price.price}
                  </span>
                  {price.per ? (
                    <span className="fs-6 text-muted">{price.per}</span>
                  ) : (
                    <></>
                  )}
                </h2>
              ) : (
                <h3
                  className="card-title display-6"
                  style={{ paddingTop: "5px" }}
                >
                  On Quote
                </h3>
              )}
            </div>
            <div>
              <small className="text-muted">{price.title}</small>
            </div>
          </div>
          <div className="card-body pt-4 pb-0 px-4">
            <ul className="list-checked list-checked-primary list-unstyled-py-2">
              {price.details.map((detail, i) => {
                return (
                  <li key={i} className="list-checked-item">
                    {detail.title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="card-footer border-0 text-center py-2 pb-0 px-4"
            style={{ zIndex: "999999" }}
          >
            <div className="d-grid">
              {price.plan === "Custom" ? (
                <button
                  className="btn btn-block btn-outline-primary btn-sm"
                  onClick={() => setIsOpen(true)}
                >
                  Meet our Team
                </button>
              ) : (orgData.plan_cycle === "yearly" && toggle) ||
                (orgData.plan_cycle === "monthly" && !toggle) ? (
                getDynamicButton()
              ) : (
                <button
                  className="btn btn-block btn-primary btn-sm"
                  onClick={() => onPlanSelection(price, "", planType)}
                >
                  {"Switch to " + price.plan}
                </button>
              )}

              <div className="mt-3">
                <a className="text-primary">
                  <em>&nbsp;</em>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupModal
        url="https://meetings.hubspot.com/pratik-w/inbound-team-?uuid=3c2bc46a-f982-49fb-b636-0f84240e9e19"
        prefill={{
          email: userData?.email,
        }}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
      />

      <Modal
        open={OpenDowngradeModal}
        onClose={setOpenDowngradeModal}
        showCloseIcon={false}
      >
        <DowngradeModal
          setOpenDowngradeModal={setOpenDowngradeModal}
          ConfirmDowngradePlan={ConfirmDowngradePlan}
          plan={price}
          planType={planType}
          orgData={orgData}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </Fragment>
  );
}
