import Cookies from "js-cookie";

export const getHeader = (headerType) => {
  const access_token = Cookies.get("user_token");
  let header = {};

  switch (headerType) {
    case "json":
      header = {
        "Content-Type": "application/json",
        Authorization: access_token ? `Bearer ${access_token}` : "",
      };
      break;

    case "multipart":
      header = {
        "Content-Type": "multipart/form-data",
        Authorization: access_token ? `Bearer ${access_token}` : "",
      };
      break;

    case "default":
      header = {
        "Content-Type": "application/json",
      };
      break;

    default:
      header = {
        "Content-Type": "application/json",
      };
  }

  return header;
};
