import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import AppRouter from "./AppRouter";
import ErrorBoundary from "components/ErrorBoundary";
import { DataProvider } from "context/DataProvider";
import { useLogout } from "hooks/auth/useLogout";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { IntercomProvider } from "react-use-intercom";
import APIErrorHandler from "components/APIErrorListener";
import NoInternetConnection from "components/NoInternetConnection";
import * as Sentry from "@sentry/react";
import { config } from "global/config";
import PublicUpload from "./PublicUpload";
import { routePaths } from "../global/routePaths";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      queryCache: new QueryCache({
        onError: (error, query) => {
          // 🎉 only show error toasts if we already have data in the cache
          if (query.state.data !== undefined) {
            toast.error(`Something went wrong: ${error.message}`);
          }
        },
      }),
    },
  },
});

export const App = () => {
  const [routeContent, setRouteContent] = useState(null);
  const handleLogout = useLogout();
  const location = useLocation();
  const token = Cookies.get("user_token");

  useEffect(() => {
    const handleRouteChange = () => {
      if (location.pathname === routePaths.publicVideoUpload) {
        setRouteContent(<PublicUpload />);
      } else if (token) {
        setRouteContent(<AppRouter />);
      } else if (!token) {
        handleLogout();
      }
    };

    handleRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <NoInternetConnection>
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <ErrorBoundary error={error} resetError={resetError} />
          )}
        >
          <APIErrorHandler>
            <IntercomProvider appId={config.INTERCOM_APP_ID}>
              <QueryClientProvider client={queryClient}>
                <DataProvider>{routeContent}</DataProvider>
                <Toaster
                  toastOptions={{
                    duration: 5000,
                  }}
                />
              </QueryClientProvider>
            </IntercomProvider>
          </APIErrorHandler>
        </Sentry.ErrorBoundary>
      </NoInternetConnection>
    </>
  );
};
